<template>
    <main class="MainCover-MAIN">
        <div class="cover-contents">
            <h1>THE ONE INMOBILIARIA: ¡AQUÍ EMPIEZA UNA HISTORIA!</h1>
            <h2>
                Con más de 20 años de experiencia en el mercado inmobiliario, nuestro compromiso con la excelencia es la base de todo lo que hacemos.
                <br><br>
                <span style="font-weight:600;">¡Confía en nosotros para hacer realidad tus sueños!</span>
            </h2>
            <div class="search-bar">
                <div class="drop-active-area" @click="switchDropTable()"></div>
                <img src="../assets/home-location-alt.svg"><p>¿Qué espacio buscas?</p><img src="../assets/drop-arrow.png" class="drop-arrow">
                <div ref="dropTable" class="drop-table" v-if="dropTableActive" @click="switchDropTable()">
                    <div><img src="../assets/silla-de-oficina.svg">Diseño de oficinas</div>
                    <div><img src="../assets/building.svg">Renta de departamentos</div>
                    <div><img src="../assets/bed.svg">Renta de habitaciones en hotel</div>
                    <div><img src="../assets/farm.svg">Renta de naves industriales</div>
                    <div><img src="../assets/visit.svg">Venta de departamentos</div>
                </div>
            </div>
        </div>
        <ImgSlider :imgList="imgList" class="ImgSlider"/>
    </main>
</template>

<script>
import ImgSlider from './ImgSlider.vue';
export default {
    name: 'MainCover',
    components: {
        ImgSlider
    },
    data(){
        return{
            imgList: [
                //require("../assets/cover1.png"),
                require("../assets/cover2.jpeg"),
                //require("../assets/cover3.jpeg"),
                require("../assets/cover4.jpeg"),
                require("../assets/cover6.jpeg"),
                require("../assets/cover5.jpeg"),
                require("../assets/cover7.jpeg"),
                //require("../assets/cover8.png"),
                //require("../assets/cover9.png"),
                require("../assets/cover10.jpeg"),
                require("../assets/cover11.jpeg"),
                require("../assets/cover12.jpeg"),
                //require("../assets/cover13.jpeg"),
            ],
            dropTableActive: false,
        }
    },

    methods: {
        switchDropTable(){
            this.dropTableActive = !this.dropTableActive;
        }
    }
}
</script>

<style scoped>
.MainCover-MAIN{
    position: relative;
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ImgSlider{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.cover-contents{
    z-index: 2;
    position: absolute;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cover-contents h1, .cover-contents h2{
    color: white;
    text-shadow: 0 0.1ch 0.3ch rgba(0, 0, 0, 0.7);
    text-align: center;
}

.cover-contents h1{
    margin-bottom: 0ch;
}

.cover-contents h2{
    margin-bottom: 2ch;
    max-width: 1080px;
    font-size: 2ch;
}

.cover-contents h2 span{
    font-size: 1.3ch;
}

.search-bar{
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 1ch;
    width: 50vw;
    height: 4.4ch;
    position: relative;
    box-shadow: 0 0 1ch rgba(0, 0, 0, 0.1);
}

.search-bar > img:not(.drop-arrow){
    margin-left: 1.4ch;
    margin-right: 1ch;
    width: 1.6ch;
}

.search-bar > p{
    color: gray;
    margin: 0;
}

.drop-arrow{
    margin: 0;
    width: 4.4ch;
    aspect-ratio: 1/1;
    position: absolute;
    right: 0;
}

.drop-active-area{
    position: absolute;
    cursor: pointer;
    width: 100%;
    min-height: 4.4ch;
    z-index: 1;
}

.drop-table{
    background-color: white;
    position: absolute;
    width: 90%;
    margin-left: 5%;
    min-height: 4.4ch;
    top: 100%;
    box-shadow: 0 0.5ch 1ch rgba(0, 0, 0, 0.2);
}

.drop-table > div{
    padding: 1ch;
    display: flex;
    align-items: center;
}

.drop-table > div:hover{
    background-color: rgb(237, 237, 237);
    cursor: pointer;
}

.drop-table > div img{
    max-width: 2ch;
    max-height: 2ch;
    margin-right: 1ch;
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){

    .MainCover-MAIN{
        height: 120vw;
    }

    h1{
        font-size: 6vw;
    }

    h2{
        font-size: 4vw;
        margin-top: 8vw;
        max-width: 90%;
    }

    .search-bar{
        margin-top: 10vw;
        width: 90vw;
        font-size: 4.5vw;
    }

}
</style>